import React, { useState } from 'react';

// === Assets === //
import chevronLeftIcon from '@assets/svg/chevron-left.svg';
import logoBlack from '@assets/svg/logo-black.svg';
import flagPl from '@assets/svg/flag-pl.svg';
import flagUa from '@assets/svg/flag-ua.svg';
import closeIcon from '@assets/svg/close-icon.svg';
import hamburgerIcon from '@assets/svg/hamburger-icon.svg';

// === Components === //
import Container from '@components/common/Container';

// === Helpers === //
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, dimensions, fonts, mixins, respondFrom } from '@styles';

// === Types === //
import { ActivePage } from '@type/common';

const NavigationWrapper = styled.div`
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  position: relative;
  z-index: 30;
`;

const NavigationInner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;

  ${respondFrom(
  breakpoints.md,
  css`
      height: 80px;
    `
)};

  ${respondFrom(
  breakpoints.lg,
  css`
      height: 100px;
    `
)};
`;

const NavigationBackTo = styled.div`
  position: absolute;
  top: 6px;
  left: -11px;
  display: none;

  > a {
    text-decoration: none;
    color: ${colors.text.default};
    font-size: ${dimensions.fontSize.tiny}px;
    line-height: 17px;
    display: flex;
    align-items: center;

    > img {
      display: inline-block;
      margin-right: 5px;
    }
  }

  ${respondFrom(
  breakpoints.md,
  css`
      display: block;
    `
)};

  ${respondFrom(
  breakpoints.md,
  css`
      &:hover {
        text-decoration: underline;
      }
    `
)};
`;

const NavigationLogoWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    font-family: ${fonts.extraBold};
    font-weight: 800;
    color: ${colors.text.default};

    ${respondFrom(
  breakpoints.md,
  css`
        font-size: ${dimensions.fontSize.small}px;
      `
)};

    ${respondFrom(
  breakpoints.lg,
  css`
        font-size: ${dimensions.fontSize.logo}px;
      `
)};
  }

  img {
    width: 82px;

    ${respondFrom(
  breakpoints.md,
  css`
        width: 120px;
      `
)};

    ${respondFrom(
  breakpoints.lg,
  css`
        width: 180px;
      `
)};
  }

  span {
    display: inline-block;
    margin-left: 10px;

    ${respondFrom(
  breakpoints.md,
  css`
        margin-left: 16px;
      `
)};

    ${respondFrom(
  breakpoints.lg,
  css`
        margin-left: 22px;
      `
)};
  }
`;

const NavigationManuWrapper = styled.div`
  display: flex;
`;

const NavigationMenu = styled.div`
  padding: 0 15px;
  position: relative;
  display: none;

  ${respondFrom(
  breakpoints.lg,
  css`
      display: block;
    `
)};

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: ${colors.black};
    opacity: 0.1;
  }
`;

const NavMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; 
    flex-flow: column;

    ${respondFrom(
  breakpoints.lg,
  css`
        flex-flow: row;
        align-items: center;
      `
)};

    li {
      text-align: center;
      margin-bottom: 45px;

      ${respondFrom(
  breakpoints.lg,
  css`
          margin-bottom: 0;
        `
)};
    }

    a {
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      color: ${colors.text.default};
      font-family: ${fonts.extraBold};
      font-weight: 800;
      padding: 5px 15px;
      font-size: 27px;

      &::after {
        content: '';
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 0;
        height: 2px;
        background-color: ${colors.ui.grayDark};
        opacity: 0;
        visibility: hidden;
        ${mixins.transitionDefault};
      }

      &.is-active {
        &::after {
          opacity: 1;
          visibility: visible;
        }
      }

      ${respondFrom(
  breakpoints.lg,
  css`
          font-size: ${dimensions.fontSize.medium}px;

          &:hover {
            &::after {
              opacity: 1;
              visibility: visible;
            }
          }
        `
)};
    }
  }
`;

const NavigationLanguageSelector = styled.div`
  position: relative;
  padding-left: 8.5px;
  margin-right: -7.5px;

  &::before {
    content: '';
    position: absolute;
    top: -13px;
    left: 0;
    height: 62px;
    width: 1px;
    background-color: ${colors.black};
    opacity: 0.1;
  }

  ${respondFrom(
  breakpoints.md,
  css`
      &::before {
        top: -22px;
        height: 80px;
      }
    `
)};

  ${respondFrom(
  breakpoints.lg,
  css`
      padding-left: 22.5px;

      &::before {
        display: none;
      }
    `
)};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    a {
      display: inline-block;
      text-decoration: none;
      padding: 5px 7.5px;

      img {
        border: 1px solid transparent;
      }

      &.is-active {
        img {
          border: 1px solid ${colors.ui.grayDark};
        }
      }
    }
  }
`;

const HamburgerIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 9px;
  margin-right: 6px;

  &.is-open {
    margin-right: 11px;
  }

  ${respondFrom(
  breakpoints.lg,
  css`
      display: none;
    `
)};
`;

const MenuMobile = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  padding-top: 62px;
  transform: translateY(-100%);
  overflow: auto;
  ${mixins.transitionDefault};
  @media (orientation: landscape) {
    justify-content: flex-start;
  }

  ${respondFrom(
  breakpoints.lg,
  css`
      display: none;
    `
)};

  &.is-open {
    transform: translateY(0);
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MenuMobileFooter = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      text-align: center;
      margin-bottom: 20px;

      a {
        text-decoration: none;
        color: ${colors.text.default};
        font-size: ${dimensions.fontSize.small}px;
      }
    }
  }
`;

interface NavigationProps {
  activePage?: ActivePage;
}

const Navigation = ({ activePage = null }: NavigationProps) => {
  const [menuOpen, toggleMenuOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { changeLanguage } = useI18next();

  const flags = {
    pl: flagPl,
    ua: flagUa,
  };

  const handleToggleMenuOpen = () => {
    toggleMenuOpen(!menuOpen);

    if (!menuOpen) {
      handleBlockScroll(true);
    } else {
      handleBlockScroll(false);
    }
  };

  const handleBlockScroll = (value: boolean) => {
    if (value) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  };

  return (
    <>
      <NavigationWrapper>
        <Container>
          <NavigationInner>
            <NavigationBackTo>
              <a href={t('nav_return_netto_url')} target="_blank">
                <img src={chevronLeftIcon} alt="" />
                <span>{t('nav_return_netto')}</span>
              </a>
            </NavigationBackTo>
            <NavigationLogoWrapper>
              <Link to="/">
                <img src={logoBlack} alt={t('nav_logo_alt')} />
                <span>{t('nav_logo_text')}</span>
              </Link>
            </NavigationLogoWrapper>
            <NavigationManuWrapper>
              <HamburgerIcon
                className={menuOpen ? 'is-open' : ''}
                onClick={() => handleToggleMenuOpen()}
              >
                {menuOpen ? <img src={closeIcon} alt="" /> : <img src={hamburgerIcon} alt="" />}
              </HamburgerIcon>
              <NavigationMenu>
                <NavMenu activePage={activePage} handleBlockScroll={handleBlockScroll} />
              </NavigationMenu>
              <NavigationLanguageSelector>
                <ul>
                  {Object.keys(flags).map((lng) => (
                    <li key={lng}>
                      <a
                        href="#"
                        className={t('language') === lng ? 'is-active' : ''}
                        onClick={(e) => {
                          e.preventDefault();
                          changeLanguage(lng);
                          handleBlockScroll(false);
                        }}
                      >
                        <img src={flags[lng]} alt={`${lng} flag`} />
                      </a>
                    </li>
                  ))}
                </ul>
              </NavigationLanguageSelector>
            </NavigationManuWrapper>
          </NavigationInner>
        </Container>
      </NavigationWrapper>

      <MenuMobile className={menuOpen ? 'is-open' : ''}>
        <div>
          <NavMenu activePage={activePage} mobile={true} handleBlockScroll={handleBlockScroll} />
        </div>
        <MenuMobileFooter>
          <ul>
            <li>
              <a href="https://www.netto.pl/o-nas/polityka-prywatnosci/" target="_blank">
                {t('footer_privacy_policy')}
              </a>
            </li>
            <li>
              <a
                href="https://www.netto.pl/o-nas/polityka-prywatnosci/pliki-cookies/"
                target="_blank"
              >
                {t('footer_cookies_policy')}
              </a>
            </li>
            <li>
              <a href={utils.regulationPdfUrl(t('language'))} target="_blank">
                {t('footer_action_regulations')}
              </a>
            </li>
            <li>
              <a href={`mailto:${t('contact_email')}?subject=${t('contact_email_subject')}`}>
                {t('footer_contact')}
              </a>
            </li>
          </ul>
        </MenuMobileFooter>
      </MenuMobile>
    </>
  );
};

interface NavMenuProps {
  activePage: ActivePage;
  mobile?: boolean;
  handleBlockScroll: Function;
}

const NavMenu = ({ activePage, mobile, handleBlockScroll }: NavMenuProps) => {
  const { t } = useTranslation();

  return (
    <NavMenuWrapper>
      {/* <ul>
        <li>
          <Link
            to="/"
            className={mobile && activePage && activePage === 'home' ? 'is-active' : ''}
            onClick={() => handleBlockScroll(false)}
          >
            {t('nav_homepage')}
          </Link>
        </li>
      </ul> */}
    </NavMenuWrapper>
  );
};

export default Navigation;
